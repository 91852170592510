
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useState, useEffect } from 'react';
import request from '../../request';
import storage from '../../storage';
import history from '../../history';
import { useParams } from 'react-router-dom';
import './Login.css'
import config from '../../Config';
import Select from 'react-select';
import i18n from '../../i18n';

function Login(props) {

    const { clientParam } = useParams()
    const [ckey, setCkey] = useState('') //return  constant variable and method 
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [selectedcompany, setSelectCompany] = useState({})
    const [keyValid, setKeyValid] = useState(false)
    const [defaultCompany, setDefaultCompany] = useState(false)
    const [companyList, setCompanyList] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const [showPage, setShowPage] = useState(false)
    

    const getClient = (param) => {
        request.get(`login/${param}`).then(response => {
            if (response.data && response.data.status) {
                setKeyValid(true)
                setCkey(param)
                storage.set("ckey", param)
                if (response.data.default_company === true) {
                    setDefaultCompany(true)
                }

                setCompanyList(response.data.companies)
                setSelectCompany(response.data.companies.filter(a =>
                    parseInt(a.compid) === parseInt(response.data.default_company_id)
                ).map(a => {
                    return {
                        label: a.compname,
                        value: a.compid,
                    }
                })[0])
            }else {
                setErrorMessage(i18n.t("Το κλειδί που πληκτρολογήσατε είναι λάθος!"))
                setTimeout(() => {
                    setErrorMessage(null)
                }, 3000)
            }
            setShowPage(true)
        }).catch(err => {
            console.debug(err)
            setShowPage(true)
        })
    }
    useEffect(() => { //function trexei 1 fora an einai kenoi oi parametrs alliws trexei kathe fora pou allazoun oi constans pou exoun dhlwthei
        // console.log(clientParam)
        if ( ((clientParam && clientParam !== '') || (storage.ckey && storage.ckey !== '')) && ckey === '') {
            const p = (clientParam && clientParam !== '') ? clientParam : storage.ckey;
            getClient(p)
        } else {
            setShowPage(true)
        }

        return () => {
            // console.log('finished')
        } //texei otan stamataei to component, trexei gia na katharisei events ( = componentWillUnmount)
    }, [])

    const isBlank = (str) => {
        return (!str || /^\s*$/.test(str));
    }

    const validatedForm = () => {
        if (isBlank(ckey) || isBlank(username) || isBlank(password)) return false

        return true
    }
    const handleKey = (e) => { 
        e.preventDefault()
        getClient(ckey)
    }
    /** Change for select */
    const handleChange = (selectedoption) => {
        setSelectCompany(selectedoption);
    }
    
    const handleLogin = (e) => {
        e.preventDefault()
        if (validatedForm()) {
            props.showHideLoading(true)
            request.post(`login`, {
                client_key: ckey,
                username,
                password,
                company_id: selectedcompany.value
            }).then(response => {
                // console.log(response)
                if (response.data && response.data.token) {
                    storage.set('username', username)
                    storage.set('quadecimals', response.data.quadecimals)
                    props.onLogin(response.data.token)
                    history.push('/')
                } else {
                    // console.log(response)
                    setErrorMessage(i18n.t('Λανθασμένα στοιχεία σύνδεσης! Παρακαλούμε όπως δοκιμάσετε εκ νέου!'))
                    setTimeout(() => {
                        setErrorMessage(null)
                    }, 3000)
                }
                props.showHideLoading(false)
            }).catch(err => {
                console.debug(err)
                props.showHideLoading(false)
            })
        } else {
            if (isBlank(username)) {
                setErrorMessage(i18n.t('Θα πρέπει να συμπληρώσετε το username για να συνεχίσετε'))
            } else if(isBlank(password)) {
                setErrorMessage(i18n.t('Θα πρέπει να συμπληρώσετε το password για να συνεχίσετε'))
            }

            setTimeout(() => {
                setErrorMessage(null)
            }, 3000)
        }
    }

    return (
        <div className="login-wrapper" style={
            {
                // backgroundImage:`url(${config.images_url}/images/background.jpg)`,
                backgroundPosition:'bottom',
                backgroundRepeat:'no-repeat',
                backgroundSize:'contain',
            }
        }>
            
                {
                !keyValid ?
                    <Form className={`fist-f ${!showPage && 'hide-login'}`} onSubmit={handleKey}>
                        <img src={`${config.images_url}/images/logo.png`}/>
                        <p>{i18n.t("Συνδεθείτε στην εφαρμογή")}</p>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            {/* <Form.Label>Username</Form.Label> */}
                            <Form.Control type="text" placeholder={i18n.t("Εισάγετε το κλειδί σας")} value={ckey} onChange={e => setCkey(e.target.value)}  />
                        </Form.Group>
                        <button className='login-button' type="submit">
                            {i18n.t("Είσοδος")}
                        </button>
                        {errorMessage && <div className="alert alert-danger"> {errorMessage} </div>}
                    </Form>
                    // <Container>
                    //     <Row>
                    //         <Col className='col-md-3 offset-md-3'>
                    //             <label htmlFor='ckey'>Κλειδί πελάτη</label>
                    //         </Col>
                    //         <Col className='col-md-3'>
                    //             <input type="text" id="ckey" value={ckey} onChange={e => setCkey(e.target.value)} />
                    //         </Col>
                    //     </Row> 
                    // </Container>
                    :
                    
                    <Form className='fist-f' onSubmit={handleLogin}>
                        <img src={`${config.images_url}/images/logo.png`}/>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            {/* <Form.Label>Username</Form.Label> */}
                            <Form.Control type="text" placeholder={i18n.t("Εισάγετε το username σας")} value={username} onChange={e => setUsername(e.target.value)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            {/* <Form.Label>Password</Form.Label> */}
                            <Form.Control type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicCompany">
                        {companyList && companyList.length > 0 && 
                            <Select onChange={handleChange} placeholder={i18n.t("Επιλέξτε εταιρία")} options={companyList.map(c => {
                                return {
                                    value: c.compid,
                                    label: c.compname
                                }
                            })}
                            value={selectedcompany}
                            ></Select> 
                        }
                        </Form.Group>
                        <button className='login-button' type="submit">
                            {i18n.t("Είσοδος")}
                        </button>
                        
                        {errorMessage && <div className="alert alert-danger"> {errorMessage} </div>}
                    </Form>
                }
            
        </div>
    )
}

export default Login;