const storage = {
  username: sessionStorage.getItem('username') ? sessionStorage.getItem('username') : '',
  token: sessionStorage.getItem('token') ? sessionStorage.getItem('token') : null,
  quadecimals: sessionStorage.getItem('quadecimals') ? sessionStorage.getItem('quadecimals') : 2,
  machine_id: sessionStorage.getItem('machine_id') ? sessionStorage.getItem('machine_id') : null,
  shift_id: sessionStorage.getItem('shift_id') ? sessionStorage.getItem('shift_id') : null,
  shift_name: sessionStorage.getItem('shift_name') ? sessionStorage.getItem('shift_name') : null,
  date: sessionStorage.getItem('date') ? new Date(sessionStorage.getItem('date')) : null,
  process_id: sessionStorage.getItem('process_id') ? sessionStorage.getItem('process_id') : null,
  order_id: sessionStorage.getItem('order_id') ? sessionStorage.getItem('order_id') : null,
  machine_name: sessionStorage.getItem('machine_name') ? sessionStorage.getItem('machine_name') : null,
  machine_status: sessionStorage.getItem('machine_status') ? sessionStorage.getItem('machine_status') : null,
  new_mstatus: sessionStorage.getItem('new_mstatus') ? sessionStorage.getItem('new_mstatus') : null,
  new_msg_count: sessionStorage.getItem('new_msg_count') ? sessionStorage.getItem('new_msg_count') : null,
  machines: sessionStorage.getItem('machines') ? JSON.parse(sessionStorage.getItem('machines')) : null,
  shifts: sessionStorage.getItem('shifts') ? JSON.parse(sessionStorage.getItem('shifts')) : null,
  waste_types: sessionStorage.getItem('waste_types') ? JSON.parse(sessionStorage.getItem('waste_types')) : null,
  ckey: sessionStorage.getItem('ckey') ? (sessionStorage.getItem('ckey')) : null,
  onChangeFns: [],

  onChange(fn) { //push function in order to do something (on change event)
    this.onChangeFns.push(fn);
    return this.onChangeFns.length - 1 
  },

  isset(key) {
    return this.hasOwnProperty(key)
  },

  clearOnChange(key) { //destroy function 
    this.onChangeFns[key] = () => {}
  },
  
  set(propertyKey, data) {
    this[propertyKey] = data;
    if (typeof data === 'string' || data instanceof String) {
      sessionStorage.setItem(propertyKey, data);
    } else {
      sessionStorage.setItem(propertyKey, JSON.stringify(data));
    }
    
    
    this.onChangeFns.forEach((fn) => {
      fn(propertyKey);
    });
  },
  clear() {
    this.username = ''
    this.token = ''
    this.machine_id = null
    this.shift_id = null
    this.shift_name = null
    this.date = null
    this.process_id = null
    this.order_id = null
    this.machine_name = null
    this.machine_status = null
    this.new_mstatus = null
  }
};
window.storage = storage;

export default storage;